<template>
    <div>
        <div v-for="(slide, index) in slides" :key="slide">
            <div class="carousel__item" v-show="index === currentSlide">
                <component :is="slide.template_filename" :slide="slide"></component>
            </div>
        </div>
    </div>
</template>

<script>
import ImmoDekeyser from "../Templates/ImmoDekeyser";
import AdFullscreen from "../Templates/AdFullscreen";

export default {
    name: "Slideshow",
    props: {
        slides: Array
    },
    components: {
        'ImmoDekeyser': ImmoDekeyser,
        'AdFullscreen': AdFullscreen
    },
    data () {
        return {
            currentSlide: 0
        }
    },
    computed: {
        totalSlides () {
            return this.slides.length
        }
    },
    mounted () {
        setInterval(() => {
            if (this.currentSlide === this.totalSlides - 1) {
                this.currentSlide = 0

                return
            }
            this.currentSlide++
        }, 12000)
    }
}
</script>

<style scoped lang="scss">
    .carousel__item {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slide {
        width: 100vw;
        height: 100vh;
    }
</style>
