<template>
    <div class="slide p-5">
        <div class="grid grid-cols-2 grid-rows-4 gap-2 h-full">
            <div class="px-5 flex items-center">
                <img src="/images/templates/ImmoDekeyser/logo.svg" alt="Immo Dekeyser" class="p-4 max-w-full">
            </div>
            <div class="flex flex-col items-end justify-center">
                <div class="contact-label mb-2 px-4 py-2">
                    www.dekeyser.immo
                </div>
                <div class="contact-label mb-4 px-4 py-2">
                    0460 966 599
                </div>
            </div>
            <div class="row-span-3 grid grid-rows-2">
                <div>
                    <h1 class="mb-3">Ik ben te koop</h1>
                    <h2>{{ slide.fields.title.value }}</h2>
                    <h4 class="mb-5">{{ slide.fields.address.value }}</h4>
                    <h3 class="price">€{{ slide.fields.price.value }}</h3>
                </div>
                <div class="grid grid-cols-2 h-full">
                    <div class="flex items-center justify-center">
                        <div class="qr-code" v-html="slide.fields.qrcode.value"></div>
                    </div>
                    <div>
                        Type: {{ slide.fields.type.value }}<br>
                        Bewoonbare oppervlakte: {{ slide.fields.builtArea?.value }}<br>
                        Totale oppervlakte: {{ slide.fields.totalArea?.value }}<br>
                        Slaappamers: {{ slide.fields.rooms?.value }}<br>
                        Badpamers: {{ slide.fields.bathrooms?.value }}<br><br>
                        <div v-if="slide.fields.description?.value" v-html="slide.fields.description.value.substring(0,300) + '...'"></div>
                    </div>
                </div>
            </div>
            <div class="row-span-3">
                <div class="grid grid-cols-2 grid-rows-3 h-full gap-5">
                    <div>
                        <img v-if="slide.fields.photo_1 !== undefined" :src="slide.fields.photo_1.value" alt="" class="object-cover h-full w-full">
                    </div>
                    <div>
                        <img v-if="slide.fields.photo_2 !== undefined" :src="slide.fields.photo_2.value" alt="" class="object-cover h-full w-full">
                    </div>
                    <div class="col-span-2">
                        <img v-if="slide.fields.photo_3 !== undefined" :src="slide.fields.photo_3.value" alt="" class="object-cover h-full w-full">
                    </div>
                    <div>
                        <img v-if="slide.fields.photo_4 !== undefined" :src="slide.fields.photo_4.value" alt="" class="object-cover h-full w-full">
                    </div>
                    <div>
                        <img v-if="slide.fields.photo_5 !== undefined" :src="slide.fields.photo_5.value" alt="" class="object-cover h-full w-full">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImmoDekeyser",
    props: {
        slide: Object
    }
}
</script>

<style scoped lang="scss">
@import "../../scss/mixins/font-face";

@include font-face("SofiaPro", "./fonts/SofiaProRegular");
@include font-face("SofiaPro", "./fonts/SofiaProMedium", 500);
@include font-face("SofiaPro", "./fonts/SofiaProSemiBold", 600);
@include font-face("SofiaPro", "./fonts/SofiaProBold", 700);

@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700');

h1 {
    font-family: 'Playfair Display', serif;
    font-size: 5rem;
}

h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    color: #3c3c3c;
}

h3 {

    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    line-height: 1.3em;
    color: #3c3c3c;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 14px;
    color: #2c2c2c;
    font-weight: 600;
}

.slide {
    font-family: "SofiaPro", sans-serif;
}

.contact-label {
    color: #ffffff;
    background: #000;
    font-family: 'SofiaPro', sans-serif;
    font-weight: bold;
    font-size: 2rem;
}

.price {
    font-family: 'SofiaPro', sans-serif;
}

</style>
