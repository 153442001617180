<template>
    <div>
        <slideshow v-if="device.playlist?.slides?.length > 0" :slides="device.playlist.slides"></slideshow>
    </div>
</template>

<script>
import Slideshow from "../Components/Slideshow";

export default {
    name: "DeviceController",
    components: {
        'slideshow': Slideshow
    },
    props: {
        deviceId: String
    },
    data () {
      return {
          device: {
              playlist: {
                  slides: []
              }
          }
      }
    },
    mounted() {

    },
    created() {
        Echo
            .private(`device.${this.deviceId}`)
            .listen('PlaylistChanged', (event) => {
                this.device = event.device
                this.device.playlist = event.playlist
            });
    },
    methods: {
        onErrorHandler (e) {
            console.error(e)
        }
    }
}
</script>

<style scoped>

</style>
