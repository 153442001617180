<template>
    <div class="slide">
        <div class="container">
            <div class="grid grid-cols-2 gap-2">
                <iframe class="absolute inset-0 w-full h-full border-0" :src="slide.fields.url.value" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdFullscreen",
    props: {
        slide: Object
    }
}
</script>

<style scoped lang="scss">
    .slide {
        width: 100vw;
        height: 100vh;
    }
</style>
