import DeviceController from "./Controllers/DeviceController";
import Echo from 'laravel-echo';

require('./bootstrap');

require('alpinejs');

const Vue = require('vue/dist/vue.esm-bundler')

try {
    if (document.querySelector('#app') !== null) {
        window.Pusher = require('pusher-js');

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.MIX_PUSHER_APP_KEY,
            wsHost: window.location.hostname,
            wsPort: 6001,
            forceTLS: false,
            disableStats: true,
        });

        Vue.createApp({})
            .component('deviceController', DeviceController)
            .mount('#app')
    }
} catch (e) {
    console.error(e)
}

